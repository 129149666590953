.rainbow-border {
	@apply rounded-2xl;
	background-image: linear-gradient(
		164deg,
		theme('colors.royal-orange') 6%,
		theme('colors.raspberry-pink') 37%,
		theme('colors.vivid-cerulean') 120%
	);
}

.rainbow-border:before,
.rainbow-border:after {
	@apply absolute bottom-0 left-0 right-0 top-0;
	content: '';
}

.rainbow-border:before {
	background-image: inherit;
	border-radius: inherit;
	mask:
		linear-gradient(#fff 0 0) content-box,
		linear-gradient(#fff 0 0);
	mask-composite: exclude;
}

.rainbow-border:after {
	opacity: 0;
}

.rainbow-border:before {
	padding: 6px;
}
