.help-card:hover {
	@apply border border-transparent;
	background-image: linear-gradient(
		164deg,
		theme('colors.royal-orange') 6%,
		theme('colors.raspberry-pink') 37%,
		theme('colors.vivid-cerulean') 120%
	);

	background-size: 0;
	background-position: 0;
	animation: 10s rotate linear infinite;
}

.help-card:before,
.help-card:after {
	@apply absolute bottom-0 left-0 right-0 top-0 rounded-2xl transition-all duration-100;
	content: '';
	z-index: -1;
}

.help-card:before {
	background-image: inherit;
	border-radius: inherit;
	mask:
		linear-gradient(#fff 0 0) content-box,
		linear-gradient(#fff 0 0);
	mask-composite: exclude;
}

.help-card:hover::after {
	opacity: 0;
}

.help-card:hover::before {
	padding: 3px;
}

@keyframes rotate {
	from {
		--angle: 0deg;
	}
	to {
		--angle: 360deg;
	}
}
@property --angle {
	syntax: '<angle>';
	initial-value: 0deg;
	inherits: false;
}
