.spinner_container {
	position: relative;
	width: 1rem;
	height: 1rem;
}

.spinner_item:first-child {
	z-index: 1;
	position: absolute;
	width: 100%;
	height: 100%;
	border-radius: 100%;
	animation: spinner-spin 1s ease infinite;
	border-style: solid;
	border-top-color: transparent;
	border-left-color: transparent;
	border-right-color: transparent;
	border-width: 3px;
	border-bottom-color: theme('colors.black');
}

.spinner_item {
	z-index: 0;
	position: absolute;
	width: 100%;
	height: 100%;
	border-radius: 100%;
	opacity: 0.8;
	animation: spinner-spin 1s ease 0.1s infinite;
	border-top-color: transparent;
	border-left-color: transparent;
	border-right-color: transparent;
	border-width: 3px;
	border-style: dotted;
	border-bottom-color: theme('colors.black');
}

@keyframes spinner-spin {
	0% {
		transform: rotate(0turn);
	}

	100% {
		transform: rotate(1turn);
	}
}
