.loading-icon {
	@apply relative flex items-center justify-center text-transparent;
}

.loading-icon:after {
	@apply absolute h-6 w-6 rounded-full border-[3px] border-white border-l-transparent;
	content: '';
	animation: rotating 2s 0.25s linear infinite;
}

@keyframes rotating {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}
