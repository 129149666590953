.on-hover img {
	transition: transform 0.5s ease;
}

.on-hover:hover img {
	transform: scale(1.1);
}

.on-hover:hover .shadow {
	@apply absolute bottom-0 left-0 right-0 top-0;
	background: linear-gradient(
		to bottom,
		rgba(0, 0, 0, 0),
		rgba(0, 0, 0, 0.3) 90%
	);
}
